

h2 {
  font-size: 20px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #363636;
  opacity: 1;
  /* Firefox */
}

.quill {
  height: 90%;
}

.ql-container {
  border: none !important;
  font-size: 15px !important;
}

.ql-toolbar {
  border: none !important;
  padding: 0px !important;
  margin-bottom: 10px !important;
}

.ql-font {
  display: none !important;
}

.ql-size {
  left: -15px;
}

.ql-align {
  width: 22px !important;
  color: white !important;
  margin-top: 2px;
}

.ql-clean {
  display: none !important;
}

.ql-picker-label {
  color: #727272 !important;
  padding: 0px !important;
}

.ql-picker-options {
  background-color: #e1e1e1 !important;
  border-radius: 4px;
  border: none !important;
}

.ql-editor {
  padding: 5px 0px;
  font-family: "Roboto";
  overflow: auto;
  overflow-wrap: anywhere;
}

.big-editor::-webkit-scrollbar {
  display: initial;
}

.ql-editor ul {
  padding-left: 5px;
}

li::before {
  color: white !important;
}

/* .ql-editor ul li:not(.ql-direction-rtl) {
    height: 26px;
} */

.ql-editor ul[data-checked="true"] li {
  text-decoration: line-through;
  opacity: 0.5;
}

.ql-editor ul[data-checked="true"] li::before {
  opacity: 0.5;
  font-size: 20px;
  top: 1px;
  position: relative;
}

.ql-editor ul[data-checked="false"] li::before {
  font-size: 20px;
  top: 1px;
  position: relative;
}

.ql-stroke {
  stroke: #727272 !important;
}

.ql-fill {
  fill: #727272 !important;
}

#tab-panel {
  background-color: #1f2529;
}

#tab-toolbar {
  background-color: #1f2529;
}

.emoji_completions {
  background-color: #1f2529;
}

.ql-snow a {
  color: #268cf2;
}

.ql-snow a:visited {
  color: #6557fd;
}

a {
  color: #268cf2;
}

a:visited {
  color: #6557fd;
}

.search-user-chat-input {
  font-family: Helvetica Neue, sans-serif;
  font-size: 18px;
  color: rgba(0, 0, 0);
  height: 40px;
  width: 90%;
  background: #f7f6f8;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 10px;
  border: none;
}
