.team-settings-wrapper {
  z-index: 2003;
  background: #0000007a;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.team-settings-overlay-close {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.team-settings-container {
  border-radius: 10px;
  background-color: #343d46;
  background: #343d46;
  position: relative;
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  width: 600px;
  overflow: auto;
  padding-bottom: 10px;
}

.team-settings-title {
  color: #e4745d;
  text-align: center;
  border-bottom: 1px solid grey;
  padding-bottom: 15px;
}

.team-settings-content-section {
  margin: 0px 25px;
}

.search-section {
  display: flex;
  justify-content: space-between;
}

.team-settings-footer-section {
  margin-top: 30px;
  text-align: center;
}
