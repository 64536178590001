.manage-user-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  max-height: 400px;
  overflow: auto;
}

.manage-users-holder {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0;
}
