.modal-user-profile {
  border-radius: 10px;
  background-color: #343d46;
  position: relative;
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  width: 700px;
}

.user-profile-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 25px;
}

.profile-image-container {
  width: 150px;
  height: 150px;
  position: relative;
}

.profile-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.edit-user-profile-icon {
  position: absolute;
  left: 134px;
  top: 134px;
  color: white;
}

.edit-user-profile-icon:hover {
  cursor: pointer;
  color: rgb(206, 200, 200);
}

.form-holder {
  width: 60%;
  display: flex;
  justify-content: space-around;
}

.form-group {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.user-profile-input-label {
  color: white;
  font-size: 12px;
}

.user-profile-input {
  background-color: transparent;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  color: white;
  border: 1px solid grey;
}

.user-profile-save-button {
  background: transparent;
  padding: 12px 24px;
  margin: 0px 5px;
  height: 40px;
  border: 1px solid #03a9f4;
  border-radius: 4px;
  color: #03a9f4;
  cursor: pointer;
  margin-bottom: 5px;
  text-align: center;
}

.user-profile-save-button:hover {
  background-color: #03a9f4;
  color: white;
}

.user-dashboard-footer{
  display: flex;
}
