.chat-button-container {
  z-index: 50;
  position: fixed;
  bottom: 10px;
  right: 20px;
}

.chat-button {
  background: transparent;
  height: 40px;
  border: 1px solid #A0A0A0;
  border-radius: 51px;
  color: #b9b9b9;
  text-align: center;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-button:hover {
  background-color: #2A323A;
  color: white;
  cursor: pointer;
}

.chat-button-text {
  margin-left: 10px;
  font-size: 14px;
}