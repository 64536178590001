.modal-body-description {
  background: transparent;
  color: #cacaca;
  font-size: 12px;
  border: none;
  width: 100%;
  min-height: 200px;
  height: calc( 100% - 70px );
  resize: none;
  margin: 10px 0;
  font-family: "Roboto";
}

.modal-body-description h1 {
  color: white;
}

.modal-body-description h2 {
  color: white;
  padding: 15px 0px;
}

.modal-body-description h3 {
  color: white;
}

.modal-body-description li {
  padding: 3px;
}

strong {
  color: white;
}
