.modal-body-title {
  background: transparent;
  font-weight: 600;
  color: #41ba8e;
  width: 100%;
  border: none;
  font-size: 16px;
  border-radius: 3px;
}

.modal-body-title:focus {
  outline: none;
  border: none;
}

.modal-body-title::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9b9b9ba9;
  opacity: 1; /* Firefox */
}
