.user-list-container {
  overflow-y: auto;
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-height: 230px;
  height: 150px;
}

.assign-user-list-item:hover {
  background: #3e444a;
  cursor: pointer;
  border-radius: 5px;
}

.assign-user-list-email {
  color: white;
  opacity: 0.7;
}

.align-center {
  align-items: center;
}