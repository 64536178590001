.modal-board-button-disabled {
  background: transparent;
  padding: 12px 24px;
  margin: 0px 5px;
  height: 40px;
  border: 1px solid grey;
  border-radius: 4px;
  color: grey;
  width: fit-content;
  width: 170px;
  cursor: pointer;
  margin-bottom: 5px;
}

.modal-board-button {
  background: transparent;
  padding: 12px 24px;
  margin: 0px 5px;
  height: 40px;
  border: 1px solid #03a9f4;
  border-radius: 4px;
  color: #03a9f4;
  width: fit-content;
  width: 170px;
  cursor: pointer;
  margin-bottom: 5px;
}

.modal-board-button:hover {
  background-color: #03a9f4;
  color: #fff;
}
