.boardnavbar {
  height: 50px;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  width: 100%;
  overflow: auto;
  position: fixed;
  top: 50px;
}

.boardnavbar::-webkit-scrollbar {
  display: none;
}

.boardnavbar-btn {
  background: #2c3136;
  color: #a0a0a0;
  padding: 5px 25px;
  text-align: center;
  line-height: 25px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
  white-space: nowrap;
  border: none;
}

.boardnavbar-btn:hover {
  background: #3e444a;
}

.boardnavbar-btn-end:hover {
  background: #3e444a;
}

.boardnavbar-scroll-container {
  max-height: 75px;
}

.add-user-icon {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0px;
  margin: 0px;
  margin-left: 10px;
  height: 35px;
  border-radius: 25px;
  background: #2c3136;
}

.add-user-icon:hover {
  background: #3e444a;
}

.boardnavbar-boardtitle {
  margin-left: 10px;
  font-weight: 500;
  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.avatar-more {
  border: none;
  padding: 0;
  margin: 0 0 0 10px;
  height: 35px;
  min-width: 35px;
  border-radius: 25px;
  background: #2c3136;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b4b4b4;
  cursor: default;
  font-size: 14px;
}

.avatar-more:hover {
  cursor: pointer;
  background: #3e444a;
}

.boardnavbar-group {
  display: flex;
  padding-left: 15px;
  align-items: center;
}

.in-meeting {
  border: 1px solid #e34b4b;
}

.in-meeting-title {
  color: #e34b4b !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2c3136;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 1px;
  bottom: 0px;
  background-color: rgb(149, 149, 149);
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #ffc107b0;
}

input:checked+.slider::before {
  background-color: white;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.assigned-to-me-holder {
  width: 150px;
  height: 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #a0a0a0;
}

.assigned-title {
  font-size: 13.3px;
  margin-left: 10px;
}

.disable {
  opacity: 0.4;
}

.socket-btn {
  background: #2c3136;
  color: #a0a0a0;
  padding: 5px 10px;
  width: 42px;
  border-radius: 5px;
  margin-right: 5px;
  border: none;
}