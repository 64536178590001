.sign-in-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.sign-in-content {
  min-width: 200px;
  max-width: 350px;
  background: #343d46;
  border-radius: 10px;
  padding: 50px 85px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.sign-in-white {
  color: white;
}

.sign-in-form {
  margin-top: 25px;
}

.sign-in-form-title {
  line-height: 1.66;
  margin: 0;
  padding: 0;
  font-weight: 900;
  color: #fff;
  font-family: Roboto;
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
}

.sign-in-form-group {
  overflow: hidden;
  margin-bottom: 20px;
  width: 300px;
}

.sign-in-form-input {
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 17px 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  color: #222;
  background: #a1a1a1;
  border: none;
}

.sign-in-form-submit-button {
  background-color: #38a88c;
  width: 100%;
  padding: 17px 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  border: none;
}

.sign-in-form-submit-button:hover {
  background-color: #0f9977;
}

.sign-in-link {
  font-weight: 700;
  color: #38a88c;
}

.sign-in-link:hover {
  color: #2a806a;
}

.sign-in-here {
  color: white;
}

.sign-in-error {
  color: red;
  font-size: 18px;
  text-align: center;
  padding: 5px 10px;
  position: relative;
}

.sign-in-form-with-google {
  margin-top: 10px;
  background-color: #37c5df;
  width: 100%;
  padding: 17px 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  border: none;
}
.sign-in-form-with-google:hover {
  background-color: #40aabd;
}
