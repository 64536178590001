.conference-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 5%;
  bottom: 5%;
  left: 5%;
  z-index: 1000;
}

.conference-minimize {
  position: fixed;
  width: 30%;
  height: 35%;
  bottom: 5px;
  z-index: 1000;
}

.conference-anchor-left {
  left: 1%;
}

.conference-anchor-right {
  right: -1.7%;
}
