.board-item {
  background: #343d45;
  padding: 15px;
  min-width: 300px;
  max-width: 300px;
  height: 140px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
}

.class-for-item-on-click-event {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}



.board-item:hover {
  box-shadow: 0 0 6px 1px rgb(111 206 235 / 60%);
  transition: 0.2s;
}

.board-item-icons {
  width: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  position: absolute;
  top: 75%;
  right: 5%;
}



.title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.board-item-title {
  margin-left: 5px;
  font-weight: bold;
  color: #ffffffe0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.board-item-info-container {
  min-height: 70px;
  display: flex;
  justify-content: space-between;
}

.sub-color {
  color: #8498aa;
}

.board-item-no-margin {
  margin: 0px;
}

.board-item-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.board-num-members {
  padding: 5px 10px;
  background: #f8f8f81c;
  border-radius: 4px;
}

.edit-board-icon:hover {
  cursor: pointer;
}

.delete-board-icon:hover {
  cursor: pointer;
}

.admin-name {
  color: #ffffff54;
}

.bolden {
  font-weight: bold;
}