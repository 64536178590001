.drop-down-button-date-picker {
  width: 170px;
  height: 40px;
  cursor: pointer;
  background-color: transparent;
  border: thin solid grey;
  border-radius: 4px;
  color: grey;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 0.5em 0.5em 1em;
  margin: 0px 5px;
  box-sizing: border-box;
  text-align: left;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.drop-down-button-date-picker:hover {
  border: thin solid rgb(163, 159, 159);
}
