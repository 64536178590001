.calendar-modal-container {
  border-radius: 10px;
  background-color: #343d46;
  background: #343d46;
  position: relative;
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  width: 96%;
  height: 90%;
  overflow: auto;
  padding-bottom: 10px;
}
