.file-profile-menu {
  position: fixed;
  right: 15px;
  top: 115px;
  border-radius: 4px;
  box-shadow: 0px 4px 20px 4px #121212b0;
  height: 450px;
  width: 400px;
  max-height: 400px;
  background-color: #2a323a;
  transition: right 300ms ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 3;
}

.file-main-title {
  top: 17px;
  left: -126px;
  padding: 0px 5px;
  color: #ababab;
  font-size: 14px;
  background: #2a323a;
  position: relative;
}

.file-item-wrapper {
  width: 87%;
  height: 100%;
  padding: 25px 13px;
  margin-top: 10px;
  border-top: 1px solid #e0e0e0;
  padding-bottom: 0px;
}

.file-close-wrapper {
  height: 100%;
  width: 100vw;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.file-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.file-helper-text {
  color: #c2c2c2;
  white-space: nowrap;
}

.file-user-name {
  color: white;
  white-space: nowrap;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-card-title {
  color: white;
  white-space: nowrap;
}

.file-assigned-user {
  color: white;
  white-space: nowrap;
}

.file-item-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.files-holder {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  overflow: hidden;
}

.file-delete-button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}
.file-delete-button:hover {
  opacity: 0.5;
}

.file-download-button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.file-download-button:hover {
  opacity: 0.4;
}

.upload-file-button {
  background: transparent;
  padding: 12px 24px;
  height: 40px;
  border: 1px solid #03a9f4;
  border-radius: 4px;
  color: #03a9f4;
  width: fit-content;
  width: 170px;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 10px;
}

.upload-file-button:hover {
  background-color: #03a9f4;
  color: #fff;
  text-align: center !important;
}

input[type="file"]::file-selector-button {
  color: #03a9f4;
  border: none;
  font-size: 15px;
  background-color: transparent;
}

.file-input {
  color: #03a9f4;
  font-size: 15px;
}

.file-input:hover {
  cursor: pointer;
  opacity: 0.5;
}

.new-file-text {
  color: #03a9f4;
  margin-bottom: 15px;
}

.new-file-text:hover {
  cursor: pointer;
  opacity: 0.5;
}

@media only screen and (max-width: 600px) {
  .file-profile-menu {
    width: 95%;
  }
}
