.graph-square {
  width: 5px;
  height: 5px;
  border-radius: 1px;
  background-color: rgba(93, 118, 139, 0.56);
  margin: 0.5px;
}

.graph-square:hover {
  background-color: rgba(166, 197, 224, 0.56);
}

.board-graph {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #8498aaab;
  row-gap: 1px;
  padding-bottom: 0.5px;
  width: 5px;
}
