.loading-screen-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-text {
  color: white;
  font-size: 16px;
}

.loading-message-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-dots:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 1200ms infinite;
  animation: ellipsis steps(4, end) 1200ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 5px;
  font-size: 20px;
  color: white;
}
