.link-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.pin-title {
  color: white;
}

.pin-title:hover {
  opacity: 0.5;
  cursor: pointer;
}

.pin-icons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}

.pin-external-link {
  color: white;
  font-size: 20px;
}

.pin-external-link:hover {
  cursor: pointer;
  opacity: 0.5;
}

.delete-pin-button:hover {
  opacity: 0.5;
  cursor: pointer;
}
