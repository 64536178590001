.card {
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 5px;
  width: 285px;
  height: 120px;
  min-height: 120px;
  display: flex;
  background-color: #343d45;
  align-items: flex-end;
  overflow: hidden;
}

.mini-card {
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 5px;
  width: 285px;
  height: 70px;
  min-height: 70px;
  display: flex;
  background-color: #343d45;
  overflow: hidden;
}

.mini-card.dragging {
  box-shadow: 0 5px 19px 2px #00000066;
}

.mini-card-color-label-holder {
  height: 100%;
  transform: rotate(45deg);
  position: relative;
  top: -27px;
}

.mini-card:hover {
  cursor: pointer;
  box-shadow: 0 0 6px 1px rgb(111 206 235 / 60%);
}

.mini-card-title {
  background: transparent;
  font-weight: 600;
  color: #41ba8e;
  width: 100%;
  border: none;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  white-space: nowrap;
  margin-top: 15px;
  margin-bottom: 0px;
}

.mini-card-inner {
  width: 90%;
}

.mini-card-footer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.card:hover {
  box-shadow: 0 0 6px 1px rgb(111 206 235 / 60%);
  cursor: pointer;
}

.hide-card {
  display: none;
}

.card.dragging {
  box-shadow: 0 5px 19px 2px #00000066;
}

.card-inner {
  width: 100%;
  height: 100%;
  padding-right: 15px;
}

.card-title {
  background: transparent;
  font-weight: 600;
  color: #41ba8e;
  width: 100%;
  border: none;
  font-size: 14px;
  border-radius: 3px;
  max-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  white-space: nowrap;
  margin-bottom: 3px;
  margin-top: 15px;
}

.card-description {
  background: transparent;
  color: #b2b2b3;
  font-size: 12px;
  border: none;
  width: 252px;
  overflow-wrap: anywhere;
  resize: none;
  height: 54px;
  overflow: auto;
  margin-bottom: 5px;
}

.card-description::-webkit-scrollbar {
  display: none;
}

.card-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 17px;
}

.card-date {
  color: rgb(107, 107, 107);
  font-size: 12px;
  width: 61px;
}

.card-avatar-image {
  width: 21px !important;
  height: 21px !important;
}

.card-avatar-more {
  border: none;
  padding: 0;
  height: 23px;
  min-width: 23px;
  border-radius: 25px;
  background: #2c3136;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b4b4b4;
  cursor: default;
  font-size: 10px;
}

.user-avatar-card-container {
  display: flex;
  column-gap: 5px;
  width: 130px;
  justify-content: flex-end;
}

.card-more-icon {
  font-size: 20px;
  color: #cecece;
  text-decoration: none;
  cursor: pointer;
  float: right;
  opacity: 0.5;
  transition: 0.2s;
}

.card-more-icon:hover {
  opacity: 1;
}

.card-color-label-holder {
  height: 100%;
  transform: rotate(45deg);
  position: relative;
  top: -52px;
}

.card-color-label {
  width: 5px;
  height: 110px;
  border-radius: 50px;
  margin: 5px 5px;
}

/*MOBILE BREAKPOINTS*/

@media only screen and (max-width: 600px) {
  .card-more-icon {
    opacity: 1;
  }
}

/* .cardlabelholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 4px;
}

.card-label {
  height: 84px;
  width: 3px;
  border-radius: 20px;
} */

.not-draggable {
  cursor: not-allowed;
  opacity: 0.4;
}

.highlight-color {
  box-shadow: 0 0 6px 0px #ffc107b0;
}