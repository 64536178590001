.text-default {
  color: aliceblue;
  text-align: center;
}

.sub-container {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
