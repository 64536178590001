ol.lst-kix_list_1-3 {
    list-style-type: none
}

ol.lst-kix_list_1-4 {
    list-style-type: none
}

.lst-kix_list_2-6>li:before {
    content: "\0025cf   "
}

.lst-kix_list_2-7>li:before {
    content: "o  "
}

ol.lst-kix_list_3-2 {
    list-style-type: none
}

.lst-kix_list_3-7>li {
    counter-increment: lst-ctn-kix_list_3-7
}

ol.lst-kix_list_1-5 {
    list-style-type: none
}

ol.lst-kix_list_3-3 {
    list-style-type: none
}

ol.lst-kix_list_3-4.start {
    counter-reset: lst-ctn-kix_list_3-4 -1
}

ol.lst-kix_list_1-6 {
    list-style-type: none
}

ol.lst-kix_list_3-4 {
    list-style-type: none
}

ul.lst-kix_list_1-0 {
    list-style-type: none
}

.lst-kix_list_2-4>li:before {
    content: "o  "
}

.lst-kix_list_2-5>li:before {
    content: "\0025aa   "
}

.lst-kix_list_2-8>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_1-2 {
    list-style-type: none
}

.lst-kix_list_3-0>li:before {
    content: "\0025cf   "
}

.lst-kix_list_3-1>li:before {
    content: "\0025cf   "
}

.lst-kix_list_3-2>li:before {
    content: " "
}

ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 -1
}

ul.lst-kix_list_3-1 {
    list-style-type: none
}

.lst-kix_list_3-5>li:before {
    content: " "
}

ul.lst-kix_list_1-1 {
    list-style-type: none
}

.lst-kix_list_3-4>li:before {
    content: " "
}

ul.lst-kix_list_3-0 {
    list-style-type: none
}

ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 -1
}

ol.lst-kix_list_1-7 {
    list-style-type: none
}

.lst-kix_list_3-3>li:before {
    content: " "
}

ol.lst-kix_list_3-5 {
    list-style-type: none
}

.lst-kix_list_1-7>li {
    counter-increment: lst-ctn-kix_list_1-7
}

ol.lst-kix_list_1-8 {
    list-style-type: none
}

ol.lst-kix_list_3-6 {
    list-style-type: none
}

ol.lst-kix_list_3-8.start {
    counter-reset: lst-ctn-kix_list_3-8 -1
}

ol.lst-kix_list_3-7 {
    list-style-type: none
}

ol.lst-kix_list_3-8 {
    list-style-type: none
}

.lst-kix_list_3-8>li:before {
    content: " "
}

.lst-kix_list_3-8>li {
    counter-increment: lst-ctn-kix_list_3-8
}

.lst-kix_list_3-6>li:before {
    content: " "
}

.lst-kix_list_3-7>li:before {
    content: " "
}

ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 -1
}

.lst-kix_list_1-2>li {
    counter-increment: lst-ctn-kix_list_1-2
}

.lst-kix_list_1-5>li {
    counter-increment: lst-ctn-kix_list_1-5
}

.lst-kix_list_1-8>li {
    counter-increment: lst-ctn-kix_list_1-8
}

ol.lst-kix_list_3-7.start {
    counter-reset: lst-ctn-kix_list_3-7 -1
}

ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 -1
}

.lst-kix_list_3-5>li {
    counter-increment: lst-ctn-kix_list_3-5
}

.lst-kix_list_3-2>li {
    counter-increment: lst-ctn-kix_list_3-2
}

.lst-kix_list_3-4>li {
    counter-increment: lst-ctn-kix_list_3-4
}

.lst-kix_list_1-4>li {
    counter-increment: lst-ctn-kix_list_1-4
}

ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 -1
}

ol.lst-kix_list_3-6.start {
    counter-reset: lst-ctn-kix_list_3-6 -1
}

ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 -1
}

ul.lst-kix_list_2-8 {
    list-style-type: none
}

ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 -1
}

ul.lst-kix_list_2-2 {
    list-style-type: none
}

.lst-kix_list_1-0>li:before {
    content: "\0025cf   "
}

ul.lst-kix_list_2-3 {
    list-style-type: none
}

ol.lst-kix_list_3-3.start {
    counter-reset: lst-ctn-kix_list_3-3 -1
}

ul.lst-kix_list_2-0 {
    list-style-type: none
}

ul.lst-kix_list_2-1 {
    list-style-type: none
}

ul.lst-kix_list_2-6 {
    list-style-type: none
}

.lst-kix_list_1-1>li:before {
    content: "\0025cf   "
}

.lst-kix_list_1-2>li:before {
    content: " "
}

ul.lst-kix_list_2-7 {
    list-style-type: none
}

ul.lst-kix_list_2-4 {
    list-style-type: none
}

ul.lst-kix_list_2-5 {
    list-style-type: none
}

.lst-kix_list_1-3>li:before {
    content: " "
}

.lst-kix_list_1-4>li:before {
    content: " "
}

ol.lst-kix_list_3-5.start {
    counter-reset: lst-ctn-kix_list_3-5 -1
}

.lst-kix_list_1-6>li {
    counter-increment: lst-ctn-kix_list_1-6
}

.lst-kix_list_1-7>li:before {
    content: " "
}

.lst-kix_list_3-3>li {
    counter-increment: lst-ctn-kix_list_3-3
}

.lst-kix_list_1-3>li {
    counter-increment: lst-ctn-kix_list_1-3
}

.lst-kix_list_1-5>li:before {
    content: " "
}

.lst-kix_list_1-6>li:before {
    content: " "
}

.lst-kix_list_3-6>li {
    counter-increment: lst-ctn-kix_list_3-6
}

li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

.lst-kix_list_2-0>li:before {
    content: "\0025cf   "
}

.lst-kix_list_2-1>li:before {
    content: "o  "
}

ol.lst-kix_list_3-2.start {
    counter-reset: lst-ctn-kix_list_3-2 -1
}

.lst-kix_list_1-8>li:before {
    content: " "
}

.lst-kix_list_2-2>li:before {
    content: "\0025aa   "
}

.lst-kix_list_2-3>li:before {
    content: "\0025cf   "
}

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.c6 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 700;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: normal
}

.c8 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: normal
}

.c1 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: normal
}

.c2 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
    height: 12pt
}

.c4 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: normal
}

.c3 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c14 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: center
}

.c10 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #000000;
    text-decoration: underline
}

.c15 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    text-decoration: underline
}

.c12 {
    background-color: #ffffff;
    max-width: 496.8pt;
    padding: 57.6pt 57.6pt 57.6pt 57.6pt
}

.c5 {
    margin-left: 36pt;
    text-indent: -18pt
}

.c11 {
    margin-left: 18pt;
    text-indent: -18pt
}

.c7 {
    padding: 0;
    margin: 0
}

.c9 {
    font-weight: 700
}

.c13 {
    color: #000000
}

.title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: justify
}
