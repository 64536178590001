.modal-board-body {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 80%;
}

.modal-pin {
  border-radius: 10px;
  background-color: #343d46;
  position: relative;
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  width: 500px;
  height: 295px;
}

.modal-pin-text {
  background-color: transparent;
  padding: 15px;
  border-radius: 4px;
  width: 60%;
  color: white;
  border: 1px solid grey;
  margin: 13px auto;
  margin-bottom: 25px;
}

.modal-pin-text::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9b9b9ba9;
  opacity: 1; /* Firefox */
}
