.sidenav-right-container {
  position: fixed;
  right: 10px;
  top: 60px;
  border-radius: 4px;
  box-shadow: 0px 4px 20px 4px #121212b0;
  background-color: #2a323a;
  transition: right 300ms ease-out;
  display: flex;
  flex-direction: column;
  width: 350px;
  z-index: 65;
}

.sidenav-right-close-wrapper {
  height: 100%;
  width: 100vw;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.sidenav-right-menu-icons-container {
  height: 8%;
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.sidenav-right-menu-title {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #565656;
}

.sidenav-right-more-icon {
  font-size: 20px;
  padding-left: 10px;
  font-weight: 800;
  color: #cecece;
  text-decoration: none;
  background: none;
  border: none;
  outline: none;
}

.sidenav-right-more-icon:hover {
  color: #747474;
}

.sidenav-right-grid-icon {
  font-size: 20px;
  font-weight: 800;
  color: #cecece;
  padding-right: 0;
  text-decoration: none;
  background: none;
  border: none;
  outline: none;
}

.sidenav-right-grid-icon:hover {
  color: #747474;
}

.sidenav-right-minus-icon {
  font-size: 20px;
  font-weight: 800;
  color: #cecece;
  padding-right: 0;
  text-decoration: none;
  background: none;
  border: none;
  outline: none;
}

.sidenav-right-minus-icon:hover {
  color: #747474;
}

.sidenav-right-menu-activity {
  height: 100%;
  margin: 15px;
  margin-top: 25px;
  border-radius: 0;
  border-top: 1px solid #e0e0e0;
}

.sidenav-right-activity-title {
  top: -15px;
  left: 15px;
  width: 79px;
  padding: 5px;
  color: #ababab;
  font-size: 14px;
  background: #2a323a;
  position: relative;
}

.sidenav-right-logout {
  cursor: pointer;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: 10px;
  color: #fff;
  text-decoration: underline;
}