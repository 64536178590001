.calendar-button {
  background: transparent;
  cursor: pointer;
  border: none;
}

.calendar-icon {
  width: 23px;
  height: 23px;
  color: #a0a0a0;
}

.calendar-icon:hover {
  filter: opacity(0.5);
}
