.column-parent {
  overflow: hidden;
  height: 100%;
}

.column-holder {
  height: calc( 100vh - 155px);
  height: -webkit-fill-available;
  display: flex;
  margin: 0px 10px;
  top: 120px;
  position: relative;
}

.new-column-button {
  background: transparent;
  border: 1px dotted;
  padding: 15px;
  color: #6b6b6b;
  height: 50px;
  min-width: 300px;
  text-overflow: ellipsis;
  border-radius: 5px;
}

.new-column-button:hover {
  background: #80808051;
  cursor: pointer;
}

.scroll-enabled {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  column-gap: 3px;
}

.testing {
/*  overflow: initial;*/
}

@media only screen and (max-width: 1145px) {
  .column-holder {
    height: calc( 100% - 200px);
  }
}