.verify-email-link {
  font-weight: 700;
  color: #38a88c;
}

.verify-email-link:hover {
  cursor: pointer;
  opacity: 0.6;
}

.info-color {
  color: white;
}

.spam-color {
  color: #E27561;
}