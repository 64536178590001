.modal-wrapper {
  z-index: 2003;
  background: #0000007a;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.modal-close-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-sub-text {
  color: white;
  margin: 55px auto;
}

.modal-main-info {
  text-align: center;
}

.modal-board-text {
  text-align: center;
  background-color: transparent;
  padding: 15px;
  border-radius: 4px;
  width: 60%;
  color: white;
  border: 1px solid grey;
  margin: 30px auto;
}

.modal-board-text::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9b9b9ba9;
  opacity: 1;
  /* Firefox */
  text-align: center;
}

.modal-board-text::-webkit-input-placeholder {
  text-align: center;
}

.modal-board-text:-moz-placeholder {
  text-align: center;
}

.modal-delete-button:hover {
  background-color: #bb4e3b;
}

.modal-cancel-button:hover {
  background-color: #696969;
}

.modal-cancel-button {
  background: transparent;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  height: 40px;
  color: white;
  margin-left: 8px;
}

.assigned-user-container {
  display: flex;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: transparent;
  color: fieldtext !important;
}

.align-text-center {
  text-align: center;
}