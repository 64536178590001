body {
  margin: 0;
  padding: 0;
  font-family: "Roboto" !important;
  background-color: #1f252a;
}

a {
  text-decoration: none;
}

.primary-text-color {
  color: #6f6f6f;
}

.kevvlar-logo-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee138;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #d6dee17a;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

.MuiDialog-paper {
  overflow: hidden !important;
}
