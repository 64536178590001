.error-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #111315;
}

.error-page-h2 {
  margin-top: 20px;
  color: beige;
  width: 235px;
}

.error-page-h2:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 1200ms infinite;      
  animation: ellipsis steps(4,end) 1200ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}
  
@keyframes ellipsis {
  to {
    width: 20px;    
  }
}
  
@-webkit-keyframes ellipsis {
  to {
    width: 20px;    
  }
}

