.activity-profile-menu {
  position: fixed;
  right: 15px;
  top: 115px;
  border-radius: 4px;
  box-shadow: 0px 4px 20px 4px #121212b0;
  width: 400px;
  height: 450px;
  max-height: 400px;
  background-color: #2a323a;
  transition: right 300ms ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.activity-main-title {
  top: 17px;
  left: -111px;
  width: 48px;
  padding: 0px 5px;
  color: #ababab;
  font-size: 14px;
  background: #2a323a;
  position: relative;
}

.activity-item-wrapper {
  width: 87%;
  padding: 25px 13px;
  margin-top: 10px;
  border-top: 1px solid #e0e0e0;
}

.activity-close-wrapper {
  height: 100%;
  width: 100vw;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.activity-item-holder {
  text-align: left;
  padding: 10px;
}

.activity-info-holder {
  font-size: 14px;
  overflow: hidden;
}

.activity-helper-text {
  color: #c2c2c2;
  white-space: nowrap;
}

.activity-user-name {
  color: white;
  white-space: nowrap;
}

.activity-card-title {
  color: #03a9f4;
  white-space: nowrap;
  cursor: pointer;
  padding-bottom: 3px;
  padding-top: 1px;
}

.activity-card-title-delete {
  color: #7c7c7c;
  white-space: nowrap;
  cursor: pointer;
  padding-bottom: 3px;
  padding-top: 1px;
}

.activity-card-title:hover {
  opacity: 0.5;
}

.activity-first-line-holder {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .activity-profile-menu {
    width: 95%;
  }
  .file-profile-menu {
    width: 95%;
  }
}
