.todopage {
  background-color: #1f252a;
}

/* .no-active-board {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 400px;
  height: 500px;
}

.content-title {
  text-align: center;
  color: #e4745d;
}

.content-body {
  text-align: center;
  color: #ffff;
} */
