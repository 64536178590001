.team-title-header {
  display: flex;
  align-items: center;
}
.team-header-icons-holder {
  margin-left: 10px;
  opacity: 0.5;
}

.team-header-icons-holder:hover {
  visibility: visible;
  cursor: pointer;
}

.team-settings-icon {
  color: white;
  font-size: 19px;
}

.new-team-btn {
  background: transparent;
  border: none;
  margin-top: 8px;
  padding: 15px;
  color: #6b6b6b;
  width: 100%;
  text-align: left;
  opacity: 1;
  border-radius: 4px;
}

.new-team-btn:hover {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  background-color: #8080801a;
  cursor: pointer;
}
