.subscription-modal {
    border-radius: 10px;
    background-color: #343d46;
    background: #343d46;
    position: relative;
    box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
    box-sizing: border-box;
    width: 520px;
    height: fit-content;
    max-width: 1200px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

.form-container-sub {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-input-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.form-label-sub {
    width: 9em;
    margin-left: 1em;
}

.stripe-pay-btn {
    margin-top: 10px;
}