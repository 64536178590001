.task-item-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
  overflow: hidden;
}

.task-item {
  color: #fff;
  padding: 10px 20px;
  font-size: 14px;
}

.task-item-board {
  color: #c2c2c2;
  font-size: 12px;
}

.task-cancel-icon {
  color: red;
  cursor: pointer;
  padding-right: 10px;
}

.board-title-link {
  width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  color: #03a9f4;
  white-space: nowrap;
  cursor: pointer;
  padding-bottom: 3px;
  padding-top: 1px;
}

.board-title-link:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #03a9f4;
}

.task-item-notification {
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background-color: #03a9f4;
  position: relative;
  top: -25px;
  left: -20px;
}

.hide-icon {
  display: none;
}

.board-invite-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 25px;
  margin-bottom: 5px;
}

.invite-btn {
  background-color: transparent;
  border-radius: 4px;
}

.accept {
  border: 1px solid #03a9f4;
  color: #03a9f4;
}

.decline {
  border: 1px solid #f27474;
  color: #f27474;
}

.accept:hover {
  cursor: pointer;
  color: white;
  background-color: #03a9f4;
}

.decline:hover {
  cursor: pointer;
  color: white;
  background-color: #f27474;
}
