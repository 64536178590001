.pricing-modal {
  border-radius: 10px;
  background-color: #343d46;
  background: #343d46;
  position: relative;
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  width: 70%;
  max-width: 1200px;
  padding-bottom: 20px;
}

.current-plan {
  color: white;
  font-size: 18px;
  padding-bottom: 5px;
}

.sub-ends-text {
  color: #8696a7;
}

.greeting-buttons-container {
  text-align: center;
}

.pricing-card {
  padding: 10px 0px;
}

.current-plan-border {
  border: 2px solid #41ba8e;
}

.cancel-sub-text {
  color: #f27474;
}

.cancel-sub-text:hover {
  cursor: pointer;
  opacity: 0.8;
}