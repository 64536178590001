.modal {
  border-radius: 10px;
  background-color: #343d46;
  background: #343d46;
  position: relative;
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  width: 70%;
  max-width: 1200px;
  height: 75%;
}

.big-card-container {
  display: flex;
  height: calc(100% - 70px);
  width: 95%;
  justify-content: space-between;
}

.close-icon-container {
  height: 100%;
}

.close-icon {
  color: white;
  padding: 10px;
  float: right;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 0px;
}

.close-icon:hover {
  opacity: 0.5;
}

.fullscreen-icon {
  color: white;
  padding: 10px;
  float: right;
  cursor: pointer;
  position: absolute;
  top: 1px;
  right: 20px;
}

.fullscreen-icon:hover {
  opacity: 0.5;
}

.fullscreen-modal {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.fullscreen-modal-big-card {
  width: 100% !important;
  max-width: 100% !important;
}

.modal-body {
  padding: 0 20px;
  color: #d1d1d2;
  font-size: 14px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-checkbox-area {
  max-height: 200px;
}

.modal-checkbox-bar {
  width: 100%;
  display: flex;
  align-items: center;
}

.modal-check-icon-check {
  border: 1px solid;
}

.modal-progress-bar-contianer {
  width: 90%;
  height: 5px;
  border-radius: 5px;
  margin: 10px;
  background-color: #dadada;
}

.modal-progress-percentage {
  display: flex;
  width: 10%;
}

.modal-check-columns {
  display: flex;
  margin-left: 25px;
  max-height: 160px;
  overflow: auto;
  flex-direction: column;
  flex-wrap: wrap;
}

.modal-checkbox-input {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  background: transparent;
}

.modal-footer-container {
  width: 200px;
  padding: 12px 20px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 10px;
}

.action-buttons-container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.date-picker {
  /*  line-height: 1;
  background: #fff;
  border: none;
  color: #030303;
  box-sizing: border-box;
  padding: 5px 5px;
  font-size: 14px;
  border-radius: 4px;
  height: 40px;*/

  /* styling */
  background-color: transparent;
  border: thin solid grey;
  border-radius: 4px;
  color: grey;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 5px 8px;
  min-width: 170px;
  height: 40px;

  /* reset */

  margin: 0px 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.date-picker:focus {
  outline: none;
}

.date-picker::before {
  cursor: pointer;
}

[type="date"] {
  background: url(./CalendarIcon.svg) 92% 50% no-repeat;
}

[type="date"]::-webkit-inner-spin-button {
  display: none;
}

[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.delete-button {
  line-height: 1;
  cursor: pointer;
  background: transparent;
  border: 1px solid #f27474;
  color: #f27474;
  box-sizing: border-box;
  padding: 12px 24px;
  font-size: 14px;
  border-radius: 4px;
  margin: 0px 5px;
  height: 40px;
  width: 170px;
  text-align: center;
}

.delete-button:hover {
  color: white;
  background-color: #f27474;
}

.save-delete-container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.select-color {
  cursor: pointer;
  width: 170px;
  height: 40px;
  /* styling */
  background-color: #343d46;
  border: thin solid grey;
  border-radius: 4px;
  position: relative;
  color: grey;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0px 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #4c4c4c, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.select-color:focus {
  outline: none;
}

.select-option {
  color: grey;
  padding: 10px;
}

.input-description-container {
  width: 100%;
  max-width: 100%;
}

/*RESPONSIVE BREAKPOINTS*/

@media only screen and (max-width: 1145px) {
  .modal {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .date-picker {
    margin-bottom: 5px;
  }

  .select-color {
    margin-bottom: 5px;
  }

  .delete-button {
    margin-bottom: 5px;
  }

  .modal-footer-container {
    justify-content: center;
    width: 100%;
    margin: 0;
    flex-direction: row;
    height: 30%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .big-card-container {
    flex-direction: column;
    width: 100%;
  }

  .input-description-container {
    height: 70%;
    width: 100%;
    max-width: 100%;
  }

  .modal-wrapper {
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;
  }
}

/* Drop down code */

ul.color-picker-dropdown-list {
  list-style: none;
  width: 300px;
  height: 240px;
  margin: 0;
  padding: 0 15px;
  position: relative;
  bottom: -15px;
  left: -15px;
  opacity: 0;
  border-radius: 4px;
  background: #343d46;
  transition: opacity 0.1s ease-in-out;
  overflow-x: hidden;
  pointer-events: none;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%),
    0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
}

.is-active .color-picker-dropdown-list {
  opacity: 1;
  /* display the dropdown */
  pointer-events: auto;
  /* make sure that the user still can select checkboxes */
}

.assign-user-image {
  margin-right: 15px;
}

.color-label-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  background-color: transparent;
}

.date-picker-button:hover {
  cursor: pointer;
  color: white;
  background-color: #03a9f4;
}

.action-buttons-container .mbsc-ios.mbsc-textfield-inner {
  visibility: hidden;
}

.drop-down-button {
  width: 170px;
  height: 40px;
  cursor: pointer;
  background-color: transparent;
  border: thin solid grey;
  border-radius: 4px;
  color: grey;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 0.5em 0.5em 1em;
  margin: 0px 5px;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #4c4c4c, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.drop-down-button:hover {
  border: thin solid rgb(163, 159, 159);
}

.is-active .assign-user-dropdown-list {
  opacity: 1;
  /* display the dropdown */
  pointer-events: auto;
  /* make sure that the user still can select checkboxes */
}

.assign-user-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  background-color: transparent;
}

.active-wrapper {
  height: 100%;
  width: 100vw;
  cursor: default;
}

ul.assign-user-dropdown-list {
  list-style: none;
  width: 330px;
  height: 300px;
  margin: 0;
  padding: 0 15px;
  position: relative;
  bottom: -15px;
  left: -15px;
  opacity: 0;
  border-radius: 4px;
  background: #343d46;
  transition: opacity 0.1s ease-in-out;
  overflow-x: hidden;
  pointer-events: none;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%),
    0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
}

.assign-user-dropdown-list li label {
  display: block;
  padding: 10px;
  transition: all 0.2s ease-out;
}

.assign-user-dropdown-list li label input {
  margin-right: 20px;
  margin-left: 10px;
  opacity: 0.7;
}

.assign-user-dropdown-list li label:hover {
  cursor: pointer;
}

.modal-title {
  color: white;
  text-align: center;
  font-size: 18px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ffffff24;
  width: 100%;
}

.assign-user-list-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 5px;
}

.pick-color-list-item {
  height: 25px;
}

.pick-color-list-item:hover {
  cursor: pointer;
  opacity: 0.8;
  background-color: #323335;
}

.assign-user-list-container {
  display: flex;
}

.assign-user-list-name {
  font-weight: 600;
  color: #ffffffe6;
}

.assign-user-label-container {
  display: flex !important;
  width: 100%;
  justify-content: space-between;
  border-radius: 4px;
}

.assign-user-label-container:hover {
  background: #3e444a;
}

.color-picker-item {
  padding: 10px;
  border-radius: 4px;
}

.color-picker-item:hover {
  background: #3e444a;
  cursor: pointer;
}


@media only screen and (max-width: 1145px) {
  ul.assign-user-dropdown-list {
    position: absolute;
    bottom: 15px;
    width: 100%;
  }

  ul.color-picker-dropdown-list {
    position: absolute;
    bottom: 15px;
    width: 100%;
  }
}