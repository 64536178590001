.admin-page-container {
  width: 100vw;
  height: 100%;
  margin-top: 80px;
  overflow: scroll;
}

.admin-page-title {
  color: #e4745d;
  text-align: center;
  font-size: 20px;
}

.admin-page-content {
  margin-top: 20px;
  margin-left: 25px;
  margin-right: 25px;
  min-width: 1500px;
}

.admin-users-table-head {
  width: 100%;
  border-bottom: 2px solid grey;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter-nav {
  display: flex;
}

.admin-users-table-data-item {
  color: white;
  margin-top: 5px;
  width: 100%;
  border-bottom: 1px solid grey;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

.admin-users-table-data-item:hover {
  background-color: #cacaca12;
}

.table-data-cell {
  font-size: 14px;
  width: 20%;
  max-width: 20%;
  margin: 0px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #cacaca;
}

.table-title {
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
}

.table-title:hover {
  cursor: pointer;
}

.admin-page-loading {
  color: white;
  text-align: center;
}

.admin-route-button {
  color: #03a9f4;
  background: none;
  border: none;
  width: 140px;
  height: 25px;
  font-size: 14px;
  font-weight: bold;
}

.admin-route-button:hover {
  cursor: pointer;
  text-decoration: underline;
}

.created-date {
  display: flex;
}

.new-badge {
  margin-left: 5px;
  color: white;
  background-color: #46b98f;
  border-radius: 25px;
  padding: 0 10px;
  text-align: center;
}

#admin-users-options {
  border-radius: 25px;
  background-color: #2c3136;
  color: #a0a0a0;
  padding: 0px 15px;
  margin-left: 10px;
}

.admin-page-load-more-users-button {
  color: white;
  font-size: 18px;
  text-align: center;
}

.admin-page-load-more-users-button:hover {
  text-decoration: underline;
  color: #03a9f4;
  cursor: pointer;
}

.admin-page-date-query-input {
  border: none;
  color: white;
  font-size: 20px;
  margin-left: 5px;
}

.admin-page-submit-button {
  background: transparent;
  padding: 12px 24px;
  margin: 0px 5px;
  height: 40px;
  border: 1px solid #03a9f4;
  border-radius: 4px;
  color: #03a9f4;
  width: -moz-fit-content;
  width: fit-content;
  width: 170px;
  cursor: pointer;
  margin-bottom: 5px;
}

.admin-page-submit-button:hover {
  background-color: #03a9f4;
  color: white;
}

.admin-page-date-query-label {
  color: white;
  font-size: 18px;
  margin-left: 15px;
}

.clear-search {
  text-align: left;
  margin-left: 15px;
  color: #03a9f4;
}

@media only screen and (max-width: 600px) {
  .admin-route-button {
    width: 50px;
    overflow: hidden;
    white-space: nowrap;
  }
}

.status-cell-container {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
}

.deactive-icon:hover {
  cursor: pointer;
  color: #e4745d;
}

.user-action-select {
  background-color: #2c3136;
  color: #cacaca;
  border: none;
}

.pagination-actions-container {
  display: flex;
  justify-content: center;
  column-gap: 20px;
}