.cardsearch-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -15px;
  height: 40px;
  margin: 0 20px;
}

.cards-search {
  height: 20px;
  border: none;
  border-radius: 20px;
  background-color: #2C3136;
  font-size: 16px;
  padding: 7.5px;  
  padding-left: 50px;
  color: white;
}

.search-focused {
  outline: none !important;
  border-color: #719ECE;
  box-shadow: 0 0 6px 0px #ffc107b0;
}

.cardsearch-margin-fix {
  margin-right: -5px;
}

.cards-search:focus { 
  outline: none !important;
  border-color: #719ECE;
  box-shadow: 0 0 6px 0px #ffc107b0;
  margin-right: 0px;
}

.cards-search::placeholder {
  margin-left: 10px;
  color: #A0A0A0;
}

.cardsearch-icon {
  position: relative;
  color: #A0A0A0;
  left: 30px;
  width: 15px;
  height: 15px;
}

.clear-search-button {
  color: #ffffff78;
  background-color: #1f2529;
  padding: 5px;
  width: 15px;
  height: 15px;
  border-radius: 25px;
  position: relative;
  right: 31px;
  text-align: center;
  vertical-align: middle;
  line-height: 11px;
  cursor: pointer;
}

.clear-search-button:hover {
  opacity: 0.5;
}