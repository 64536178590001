.md-switching-view-cont .mbsc-segmented {
  max-width: 350px;
  margin: 0 auto;
}

.md-switching-view-cont .mbsc-calendar-grid-item {
  height: 490px;
  box-sizing: border-box;
}

.cal-header-picker {
  flex: 1 0 auto;
}

.cal-header-nav {
  width: 200px;
}

.mbsc-material.cal-header-prev {
  order: 1;
}

.mbsc-material.cal-header-next {
  order: 2;
}

.mbsc-material.cal-header-nav {
  order: 3;
}

.mbsc-material .cal-header-picker {
  order: 4;
}

.mbsc-material .cal-header-today {
  order: 5;
}

.mbsc-windows.cal-header-nav {
  order: 1;
}

.mbsc-windows.cal-header-prev {
  order: 2;
}

.mbsc-windows.cal-header-next {
  order: 3;
}

.mbsc-windows .cal-header-picker {
  order: 4;
}

.mbsc-windows .cal-header-today {
  order: 5;
}

.md-custom-event-cont {
  font-size: 12px;
  font-weight: 600;
  height: 100%;
  background: #fff;
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 0 10px 10px 0;
  overflow: hidden;
}

.md-custom-event-wrapper {
  position: relative;
  background: rgba(255, 255, 255, 0.5);
  height: 100%;
  min-height: 135px;
  box-sizing: border-box;
  border-radius: 0 10px 10px 0;
  transition: background 0.15s ease-in-out;
}

.mbsc-schedule-event-hover .md-custom-event-cont .md-custom-event-wrapper {
  background: rgba(255, 255, 255, 0.3);
}

.mbsc-schedule-event-active .md-custom-event-cont .md-custom-event-wrapper {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12),
    0 1px 3px -1px rgba(0, 0, 0, 0.2);
}

.mbsc-schedule-event-hover .md-custom-event-allday-title {
  opacity: 0.8;
}

.mbsc-schedule-event-active .md-custom-event-allday-title {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12),
    0 1px 3px -1px rgba(0, 0, 0, 0.2);
}

.event-container {
  background-color: #343d45;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mbsc-windows-dark.mbsc-eventcalendar {
  height: 100vh;
}

.mbsc-schedule-time-col {
  color: gray;
}

.mbsc-schedule-time-indicator-x.mbsc-ltr {
  color: red !important;
}

.mbsc-schedule-time-indicator-time {
  color: red !important;
  font-size: 10px !important;
  font-weight: bold !important;
}

.mbsc-schedule-header-dayname {
  color: white;
}

.mbsc-schedule-header-dayname {
  color: white;
}

.mbsc-calendar-button.mbsc-button.mbsc-reset {
  color: white;
}

.mbsc-schedule-all-day-text {
  color: white;
}

.mbsc-schedule-time {
  color: white;
}

.mbsc-popup-body {
  background-color: #343d45 !important;
  border-radius: 10px;
  padding: 10px !important;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.5);
}

.title-styles {
  color: red !important;
}

.mbsc-popup-header {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #f18067;
  padding-bottom: 10px;
  border-bottom: 1px solid #ffffff24;
  margin-bottom: 8px;
}

.mbsc-ios.mbsc-textfield {
  color: white !important;
}

.mbsc-ios.mbsc-textarea {
  border: 1px solid #7e7e7e !important;
  border-radius: 6px !important;
  background-color: #343d45;
  height: 80px !important;
}

.mbsc-ios.mbsc-textarea-inner {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mbsc-textfield-wrapper-underline::before {
  border: none !important;
}

.mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:after {
  border: none !important;
}

.mbsc-popup-buttons {
  display: flex;
  justify-content: center;
}

.mbsc-label-inline {
  color: white !important;
}

.kevvlar-button-style {
  width: 120px;
  height: 35px;
  border: 1px solid #03a9f4 !important;
  border-radius: 5px;
  color: #03a9f4 !important;
}

.kevvlar-button-style-danger {
  width: 120px;
  height: 35px;
  border: 1px solid #f27474 !important;
  border-radius: 5px;
  color: #f27474 !important;
}

.mbsc-button-group {
  display: flex !important;
  justify-content: space-evenly !important;
}

.mbsc-popup-content {
  display: flex;
  flex-direction: column;
}

.mbsc-ios.mbsc-label-inline.mbsc-ltr {
  padding-left: 0 !important;
  display: flex !important;
  align-items: center !important;
}

.mbsc-calendar-controls {
  background: #343d45;
}

.mbsc-schedule-grid-scroll {
  background: #343d45;
}

.mbsc-popup-wrapper {
  background: transparent;
}

.mbsc-ios.mbsc-calendar-width-md .mbsc-schedule-all-day-wrapper {
  background-color: #343d45;
}

.mbsc-schedule-time-col {
  background-color: #343d45;
}

.mbsc-ios.mbsc-calendar-button.mbsc-button {
  color: white !important;
}

.mbsc-ios.mbsc-schedule-header-day {
  color: white !important;
}

.mbsc-ios.mbsc-schedule-header-dayname-curr {
  color: white !important;
}

.mbsc-ios.mbsc-schedule-header-day.mbsc-selected {
  background-color: #03a9f4 !important;
}

.mbsc-ios.mbsc-schedule-event-end .mbsc-schedule-event-background {
  background-color: #141414;
}

.mbsc-ios.mbsc-schedule-event-title {
  color: white !important;
}

.mbsc-ios.mbsc-schedule-event-range {
  color: white !important;
}

.mbsc-popup {
  color: #d0d0d0 !important;
}

.border-remover {
  border: none !important;
}

.title-input {
  background-color: transparent;
  color: white;
  height: 40px;
  border: 1px solid #7e7e7e;
  border-radius: 5px;
  margin-top: 5px;
  padding: 10px;
}

.title-input:focus {
  outline: none;
}

.title-input::placeholder {
  color: grey;
}

.mbsc-ios.mbsc-datepicker .mbsc-calendar-slide {
  background-color: #343d45 !important;
  color: white !important;
}

.mbsc-calendar-day-outer .mbsc-calendar-day-text {
  color: white !important;
}

.mbsc-ios.mbsc-calendar-day-text {
  color: white !important;
}

.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
  background-color: #03a9f4 !important;
  border-color: #03a9f4 !important;
}

.mbsc-ios.mbsc-scroller-wheel-group {
  background-color: #343d45;
}

.mbsc-ios.mbsc-scroller-pointer .mbsc-scroller-wheel-item {
  color: white !important;
}

.mbsc-ios.mbsc-scroller-pointer .mbsc-scroller-wheel-item.mbsc-selected {
  color: #03a9f4 !important;
}

.description-input {
  background-color: transparent;
  margin-top: 10px;
  border: 1px solid #7e7e7e;
  color: white;
  height: 60px;
  resize: none;
  border-radius: 5px;
  padding: 10px;
  font-family: 'Roboto' !important;
}

.description-input:focus {
  outline: none;
}

.description-input::placeholder {
  color: grey;
}

.description-input[type='text'] {
  padding-left: 15px;
}

.mbsc-ios.mbsc-textfield {
  border: none !important;
}

.date-picker-container {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  margin-top: 35px;
  margin-bottom: 15px;
}

.mbsc-ios.mbsc-textfield {
  width: 100% !important;
  padding: 0 !important;
}

.mbsc-ios.mbsc-label-inline {
  line-height: 0 !important;
}

.mbsc-ios.mbsc-calendar-cell {
  background-color: transparent !important;
}

.mbsc-ios.mbsc-datepicker .mbsc-calendar {
  background-color: #343d45 !important;
}

.mbsc-ios.mbsc-schedule-all-day-text {
  color: #c9c9c9 !important;
}

.mbsc-ios.mbsc-schedule-time {
  color: #c9c9c9 !important;
}

.mbsc-ios.mbsc-schedule-item {
  border-color: #bebebe54 !important;
}

.mbsc-ios.mbsc-schedule-all-day-item:after {
  border-color: #bebebe54 !important;
}

.mbsc-ios.mbsc-schedule-header-item {
  border-color: #bebebe54 !important;
}

.mbsc-ios.mbsc-schedule-column {
  border-color: #bebebe54 !important;
}

.mbsc-ios.mbsc-schedule-resource-group {
  border-color: #bebebe54 !important;
}

.mbsc-ios.mbsc-schedule-all-day-wrapper {
  border-color: #bebebe54 !important;
}

.mbsc-ios.mbsc-schedule-header {
  background: #343d45;
  border-color: #bebebe54 !important;
}

.mbsc-ios.mbsc-schedule-time-indicator-time {
  background-color: transparent !important;
  color: white !important;
}

.mbsc-ios.mbsc-schedule-time-indicator {
  border-color: #ca4646 !important;
}

.mbsc-ios.mbsc-schedule-time-indicator-day:before {
  background-color: #ca4646 !important;
}

.mbsc-ios.mbsc-schedule-event-end .mbsc-schedule-event-bar.mbsc-ltr {
  z-index: 1 !important;
}

.event-details-date-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  color: white;
}

.mbsc-ios.mbsc-textfield-inner {
  position: unset !important;
  flex: none !important;
  z-index: unset !important;
}

.mbsc-ios.mbsc-textfield {
  padding: 0;
}

.mbsc-ios.mbsc-form-control-wrapper {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
}

.mbsc-ios.mbsc-select {
  background: none !important;
}

.mbsc-popup-body {
  overflow: auto !important;
}

.mbsc-popup-content {
  overflow: unset !important;
}

.mbsc-material.mbsc-popup-arrow {
  background: none !important;
  box-shadow: none !important;
}

.recurring-modal-container {
  flex: 1;
}

.recurring-title {
  margin-bottom: 10px;
  color: white;
}

.user-list-title {
  color: white;
}

.view-event-header {
  color: white;
}

.view-event {
  color: white;
  opacity: 0.7;
}

.view-event-container {
  margin-bottom: 10px;
}

.mbsc-ios.mbsc-schedule-event:after {
  background-color: #404a53 !important;
}

.mbsc-ios.mbsc-textfield-inner {
  color: white !important;
}

.mbsc-ios.mbsc-textfield-wrapper-underline {
  background: #343d45 !important;
}

.mbsc-alert-title {
  color: #f27474 !important;
}

.mbsc-alert-message {
  color: white !important;
}

.mbsc-ios.mbsc-popup-button-flex.mbsc-font.mbsc-button-flat {
  border: none !important;
}

.mbsc-ios.mbsc-calendar-cell-text {
  color: white;
}
