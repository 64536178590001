.homepage {
  z-index: -2;
  /* background: radial-gradient(ellipse at bottom, #1b2735 0, #090a0f 100%);  */
  background: radial-gradient(ellipse 80% 50% at 50% -20%,rgba(120,119,198,0.3),var(--transparent));
}

.bg-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://static.vecteezy.com/system/resources/previews/001/257/315/large_2x/black-carbon-fiber-material-texture-vector.jpg');
  opacity: 0.1;
  z-index: -1;
}

.homepage-effect {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(230, 230, 247, 0);
  opacity: 0.1;
  background: linear-gradient(135deg, #00000055 25%, transparent 25%) -6px 0/ 12px 12px, linear-gradient(225deg, #000000 25%, transparent 25%) -6px 0/ 12px 12px, linear-gradient(315deg, #00000055 25%, transparent 25%) 0px 0/ 12px 12px, linear-gradient(45deg, #000000 25%, rgba(230, 230, 247, 0) 25%) 0px 0/ 12px 12px;
}

.homepage-nav {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0px auto;
}

.homepage-nav-btns a {
  text-decoration: none;
}

.homepage-logo {
  color: white;
  margin-left: 15px;
}

.homepage-nav-holder {
  display: flex;
}

.homepage-nav-btns {
  padding: 0px 15px;
  color: grey;
  cursor: pointer;
  text-decoration: none;
}

.homepage-nav-btns a:visited {
  color: grey;
}

.homepage-nav-btns:hover {
  color: whitesmoke;
}

.hero-section {
  max-width: 1200px;
  width: 100%;
  min-height: 90vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.free-trial {
  margin-bottom: 50px;
  height: 50px !important;
  width: 250px !important;
  font-size: 16px;
}

.fade {
  opacity: 0;
  animation: fadeIn 1s forwards 1s; /* 2s delay */
  transform: scale(0.9);
}

.fade-last {
  opacity: 0;
  animation: fadeIn 1s forwards 3s; /* 2s delay */
  transform: scale(0.9);
}

.fade-slower {
  opacity: 0;
  animation: fadeIn 1s forwards 2.5s; /* 2s delay */
  transform: scale(0.9);
}

.hero-holder {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.hero-image {
  width: 87%;
  animation: fadeIn 1s forwards 2s; /* 2s delay */
  opacity: 0;
  transform: scale(0.95);
  border-radius: 10px;
  box-shadow: 0px 4px 13px #00000096;
}

.heromeetings {
  position: absolute;
  width: 32%;
  bottom: -45px;
  left: 0;
}

.herochat {
  position: absolute;
  width: 32%;
  bottom: -45px;
  right: 0;
}

.layerone {
  position: absolute;
  width: 91%;
  height: 90%;
  z-index: -1;
  opacity: 0;
  animation: fadeIn 1s forwards 1.5s;
  transform: scale(0.9);
}

.layertwo {
  position: absolute;
  width: 95%;
  height: 81%;
  z-index: -2;
  opacity: 0;
  animation: fadeIn 1s forwards 1s;
  transform: scale(0.9);
}

@keyframes fadeIn {
  to {
      opacity: 1;
      transform: scale(1);
  }
}

.hero-helper-text {
  color: #b5b5b5;
  max-width: 650px;
  text-align: center;
  padding: 25px;
  line-height: 1.5;
}

.hero-heading {
  color: #e4745d;
  font-size: 3em;
  max-width: 670px;
  text-align: center;
  padding: 25px 0px 0px;
  font-weight: 700;
  margin-top: 65px;
}

.helper-section {
  height: 500px;
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.helper-section-group {
  padding: 5px 25px;
  width: 300px;
}

.helper-icon {
  color: white;
  margin-bottom: 13px;
}

.overline {
  font-family: monospace;
  color: #47B98F;
  border-bottom: 1px solid #47B98F;
  width: fit-content;
  padding-bottom: 10px;
  font-size: 14px;
}

.helper-title {
  color: #47B98F;
  margin-bottom: 12px;
  font-weight: 500;
}

.helper-description {
  color: #b5b5b5;
  line-height: 1.5;
}

.calc-section {
  background-color: #15191D;
  padding: 100px 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.results-card {
  padding: 10px 25px;
  border-radius: 4px;
  margin: 25px 0px;
  text-align: center;
}

.input-title {
  color: #d0d0d0;
  padding: 10px 0px;
}

.calc-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.input-section {
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;
}

.tool-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 25px;
}

.tool-selection {
  width: 50px;
  height: 50px;
  margin: 15px;
  cursor: pointer;
  color: white;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 3px solid #15191d;
}

.selected {
  border: 3px solid #03A9F4 !important;
}

.team-selection {
  width: 100px;
  padding: 15px 25px;
  border-radius: 4px;
  border: 1px solid #a2a2a2;
  color: #a2a2a2;
  margin: 15px;
  cursor: pointer;
}

.results-section {
  width: 800px;
  text-align: left;
  background-color: #373d4543;
  padding: 25px;
  border-radius: 4px;
}

.results {
  color: white;
  font-size: 34px;
  font-weight: 500;
}

.showcase-section {
  background-color: #15191D;
  padding: 150px 0px;
}

.showcase-group {
  display: flex;
  justify-content: space-around;
  margin: auto;
  align-items: center;
  height: 500px;
  max-width: 1200px;
}

.showcase-text-holder {
  width: 50%;
  padding: 0px 50px;
}

.showcase-title {
  font-size: 2rem;
  color: #e4745d;
  font-weight: 500;
  padding: 15px 0px;
}

.showcase-content {
  font-size: 1rem;
  color: white;
  padding: 10px 0px;
  line-height: 1.5;
}

.cta-holder {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.showcase-link {
  color: white;
  background-color: #03A9F4;
  padding: 10px 40px;
  border-radius: 4px;
  cursor: pointer;
}

.showcase-link:hover {
  opacity: 0.4;
}

.showcase-img-holder {
  height: 400px;
  width: 50%;
  margin: 0px 50px;
  border-radius: 5px;
}

.reverse {
  flex-direction: row-reverse;
}

.cta-section {
  height: 300px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #0e1013;
}

.cta-text {
  font-size: 2.5rem;
  color: white;
}

.cta-btn {
  background: transparent;
  padding: 12px 24px;
  text-align: center;
  border: 1px solid #03a9f4;
  border-radius: 4px;
  color: #03a9f4;
  width: 170px;
  cursor: pointer;
}

.cta-btn:hover {
  background-color: #03a9f4;
  color: white;
}

.footer-section {
  width: 100%;
  background-color: #0e1013;
}

.footer-nav {
  height: 70px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pricing-holder {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pricing-title-holder {
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
}

.pricing-card {
  width: 300px;
  height: 450px;
  border: 1px solid #676767;
  background-color: #343c4578;
  border-radius: 5px;
  margin: 0px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricing-plan-name {
  color: #e4745d;
  font-size: 22px;
  font-weight: 500;
  margin-top: 25px;
  margin-bottom: 15px;
}

.pricing-cost {
  color: white;
  font-size: 24px;
  font-weight: 500;
  width: 80%;
  text-align: center;
  border-top: 1px solid #676767;
  padding: 20px;
}

.pricing-features-holder {
  margin: 15px 0px;
  height: 200px;
}

.pricing-feature {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.checkmark-green {
  color: #3CBA8E;
}

.savings-positive {
  color: #3cba8e
}

.x-red {
  color: #F27474;
}

.pricing-feature-name {
  color: #A9A9A9;
  padding-left: 15px;
}

/* RESPONSIVE */
@media only screen and (max-width: 800px) {
  .hero-heading {
    padding: 25px 15px;
  }
  .input-section {
    width: 100%;
  }
  .results-section {
    width: 100%;
  }
  .helper-section {
    display: flex;
    flex-direction: column;
    margin: 15px 0px;
  }
  .showcase-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 600px;
    margin: 15px 25px;
  }
  .pricing-holder {
    display: inline;
  }
  .footer-nav {
    display: inline;
  }
  .homepage-nav {
    display: inline;
  }
  .homepage-logo {
    display: flex;
    justify-content: center;
    margin: 0;
  }
  .homepage-nav-holder {
    justify-content: center;
  }
  .helper-section-group {
    margin: auto;
  }
  .showcase-text-holder {
    margin: auto;
    padding: 0;
    width: 80%;
  }
  .showcase-section {
    padding: 50px 0px;
  }
  .showcase-img-holder {
    height: auto;
    width: 100%;
    margin: auto;
    border-radius: 5px;
  }
  .pricing-card {
    margin: auto;
    margin-bottom: 10px;
  }
  .cta-section {
    padding: 0px 25px;
  }
  .footer-section {
    padding-bottom: 10px;
  }
}