.pins-close-wrapper {
  height: 100%;
  width: 100vw;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.pin-profile-menu {
  position: fixed;
  right: 15px;
  top: 115px;
  border-radius: 4px;
  box-shadow: 0px 4px 20px 4px #121212b0;
  height: 450px;
  width: 400px;
  max-height: 400px;
  min-width: 300px;
  background-color: #2a323a;
  transition: right 300ms ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 3;
}

.pin-main-title {
  top: 17px;
  left: -126px;
  padding: 0px 5px;
  color: #ababab;
  font-size: 14px;
  background: #2a323a;
  position: relative;
}

.pin-item-wrapper {
  width: 87%;
  height: 100%;
  padding: 25px 13px;
  margin-top: 10px;
  border-top: 1px solid #e0e0e0;
}

.new-pin-text {
  color: #03a9f4;
  margin-bottom: 15px;
}

.new-pin-text:hover {
  cursor: pointer;
  opacity: 0.5;
}

@media only screen and (max-width: 600px) {
  .pin-profile-menu {
    width: 95%;
  }
}
