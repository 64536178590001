.appbar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #343d46;
  justify-content: space-between;
  box-shadow: 0 1px 10px 5px rgb(0 0 0 / 10%);
  height: 50px;
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 50;
}

.board-lead {
  background-color: transparent;
}

.appbar-menu-container {
  display: flex;
  align-items: center;
  left: 0;
  max-width: 200px;
  position: relative;
  padding-left: 5px;
}

.appbar-ham-icon-container {
  cursor: default;
  height: 100%;
  display: flex;
  align-items: center;
}

.appbar-ham-icon {
  padding-right: 10px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.41);
}

.appbar-input {
  color: #dedede;
  border: none;
  font-size: 16px;
  border-radius: 3px;
  background: transparent;
  padding: 0;
}

.appbar-logo {
  font-weight: 700;
  display: flex;
  margin: 0px 5px;
  background: none;
  border: none;
  cursor: pointer;
}

.appbar-logo:hover {
  filter: opacity(0.5);
}

.appbar-user-menu-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
