.column {
  display: flex;
  min-width: 300px;
  flex-direction: column;
  padding-right: 10px;
  margin-bottom: 25px;
  border-radius: 5px;
}

.column-handle {
  width: 100%;
  white-space: nowrap;
}

.card-container {
  display: flex;
  flex-direction: column;
  height: calc( 100vh - 280px);
  overflow: auto;
}

.card-container::-webkit-scrollbar {
  display: none;
}

.scroll-container {
  height: calc(100% - 140px);
}

.column-header-container {
  display: flex;
  width: 300px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: #1f252a;
}

.column-header-title {
  font-weight: 500;
  color: #e4745d;
  background: none;
  display: inline-block;
  font-size: 16px;
  border: none;
  margin-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
}

.column-header-icon-container {
  display: flex;
  flex-direction: row;
  opacity: 0;
  transition: 0.2s;
  width: 100%;
  justify-content: flex-end;
}

.column-header-icon-container:hover {
  opacity: 1;
  cursor: pointer;
}

.column-header-trash-icon {
  font-size: 20px;
  font-weight: 800;
  color: #cecece;
  padding-right: 0;
  background: none;
}

.column-header-trash-icon:hover {
  opacity: 0.5;
}

.column-header-edit-icon {
  margin-left: 5px;
  font-size: 20px;
  font-weight: 800;
  color: #cecece;
  padding-right: 0;
  background: none;
}

.new-card-button {
  background: transparent;
  border: none;
  margin-top: 8px;
  padding: 15px;
  color: #6b6b6b;
  width: 100%;
  text-align: left;
  opacity: 1;
  border-radius: 4px;
}

.new-card-button:hover {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  background-color: #8080801a;
  cursor: pointer;
}

.new-card-button:active {
  box-shadow: none;
}

.finished-column {
  opacity: 0.3;
}

/*MOBILE BREAKPOINTS*/

@media only screen and (max-width: 600px) {
  .column-header-icon-container {
    opacity: 1;
  }
}

.in-use {
  cursor: not-allowed;
  opacity: 0.4;
}
