.chat-page-modal {
  position: fixed;
  right: 20px;
  bottom: 45px;
  z-index: 100;
}

.chat-modal-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #0000007a;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

/*MOBILE BREAKPOINTS*/

@media only screen and (max-width: 600px) {
  .chat-page-modal {
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
  }
}