.notification-modal-container {
  z-index: 1000;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.notification {
  position: fixed;
  top: 10%;
  left: 99%;
  transform: translate(-99%, -10%);
  width: 250px;
  height: 80px;
  background: rgb(28, 43, 58);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 7px;
}

.notification:hover {
  background: rgb(38, 54, 70);
}

.notification-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}

.notification-container:hover {
  cursor: pointer;
}

.notification-icon-container {
  margin-right: 5px;
  margin-left: 10px;
}

.notification-calendar-icon {
  width: 23px;
  height: 23px;
  color: #03a9f4;
}

.notification-event-title {
  font-weight: 700;
  font-size: 16px;
  color: white;
}

.notification-event-timing {
  font-weight: 400;
  font-size: 12px;
  color: rgba(197, 193, 193, 0.87);
}

.notification-close-container {
  position: absolute;
  top: 5%;
  bottom: 0%;
  left: 92%;
}

.notification-close-icon {
  color: white;
}

.notification-close-icon:hover {
  cursor: pointer;
  color: rgb(100, 98, 98);
}
