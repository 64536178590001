.search-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -15px;
  height: 40px;
  margin-bottom: 50px;
}

.boards-search {
  width: 200px;
  height: 20px;
  border: none;
  border-radius: 20px;
  background-color: #343d46ab;
  font-size: large;
  padding: 10px;  
  padding-left: 50px;
  color: white;
}

.boards-search:focus { 
  outline: none !important;
  border-color: #719ECE;
  box-shadow: 0 0 6px 0px #ffc107b0;
}

.boards-search::placeholder {
  margin-left: 10px;
  color: white;
}

.search-icon {
  position: relative;
  color: white;
  left: 30px;
  width: 15px;
  height: 15px;
}
