.board-list-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 15px;
}

.boards-list {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 15px;
}