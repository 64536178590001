.modal-board {
  border-radius: 10px;
  background-color: #343d46;
  background: #343d46;
  position: relative;
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  width: 400px;
  height: 250px;
}

.manage-users-modal-board {
  border-radius: 10px;
  background-color: #343d46;
  background: #343d46;
  position: relative;
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  width: 600px;
}

.modal-board-body {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 80%;
}

.modal-board-title {
  text-align: center;
  color: #fff;
  margin-top: 0px;
}

/*RESPONSIVE BREAKPOINTS*/

@media only screen and (max-width: 600px) {
  .modal {
    width: 100%;
    height: 100%;
  }
}
